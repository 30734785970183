import {
  COMPLAINTS,
  CONCLUSIONS,
  DESTINATIONS,
  EXTENSIBLE_LIST,
  FIX_LIST,
  VARIABLES,
} from '../configuration/reducer_types'

export const editorHelper = {
  print_r (data) {
    if (this.debug) {
      console.info(data)
    }
  },

  /**
  * check element awaliable edit
  */
  checkEditable (node) {
    if (!node && !node.classList) {
      return false
    }

    return Boolean(
      node.classList.contains(CONCLUSIONS) ||
      node.classList.contains(VARIABLES) ||
      node.classList.contains(FIX_LIST) ||
      node.classList.contains(EXTENSIBLE_LIST) ||
      node.classList.contains(COMPLAINTS) ||
      node.classList.contains(DESTINATIONS)
    )
  },

  // Проверяет, что node состоит ТОЛЬКО из абобусов
  checkBogusNode (node) {
    if (!node) return false
    if (node.nodeType !== 3) return false
    let isBogus = true
    for (let i = 0; i < node.data.length; i++) {
      if (node.data.charCodeAt(i) !== 65279) {
        isBogus = false
      }
    }

    return isBogus
  },

  /**
  * find emtpy indexes witdh end in row
  */
  findNotEmptyIndexWithEnd (node) {
    const arIndex = []
    let stopFlag = false
    if (this.checkBogusNode(node)) return false

    if (node.nodeType !== 3) return false
    for (let i = (node.data.length - 1); i >= 0; i--) {
      if (stopFlag) {
        return
      }
      if (node.data.charCodeAt(i) === 65279) {
        arIndex.push(i)
      } else {
        arIndex.push(i + 1)
        stopFlag = true

        return arIndex
      }
    }

    return arIndex
  },

  findNotEmptyIndexWithStart (node) {
    const arIndex = []
    let stopFlag = false
    if (this.checkBogusNode(node)) {
      return false
    }
    if (node.nodeType !== 3) {
      return false
    }
    for (let i = 0; i < node.data.length; i++) {
      if (stopFlag) {
        return
      }
      const char = node.data.charCodeAt(i)

      //32 == 'space'
      if (char === 65279 || char === 32) {
        arIndex.push(i)
      } else {
        arIndex.push(i)
        stopFlag = true

        return arIndex
      }
    }

    return arIndex
  },

  findFistContentNodeInParentNode (node) {
    if (!node) {
      return false
    }
    if (node.childNodes.length === 0) {
      return false
    }

    for (let i = 0; i < node.childNodes.length; i++) {
      const checkNode = node.childNodes[i]
      if (checkNode.nodeType === 3 && !this.checkBogusNode(checkNode)) {
        return node
      } else if (checkNode.nodeType === 1) {
        if (this.findFistContentNodeInParentNode(checkNode)) {
          return this.findFistContentNodeInParentNode(checkNode)
        }
      }
    }

    return false
  },

  findNextNode (node) {
    if (!node) {
      return false
    }
    if (node.nodeName === 'TD' ||
      node.nodeName === 'P' ||
      node.nodeName === 'DIV'
    ) {
      return false
    }
    const nextSib = node.nextSibling

    if (nextSib) {
      if (nextSib.nodeType === 3) {
        if (!this.checkBogusNode(nextSib)) {
          return nextSib
        }

        return this.findNextNode(nextSib)
      } else if (nextSib.nodeType === 1) {
        if (nextSib.childNodes.length > 0) {
          const firstContentNode = this.findFistContentNodeInParentNode(nextSib)
          if (firstContentNode) {
            return firstContentNode
          }
        }

        return this.findNextNode(nextSib)
      }
    } else if (node.parentNode) {
      return this.findNextNode(node.parentNode)
    }
  },

  findLastContentNodeInParentNode (node) {
    if (!node) {
      return false
    }
    if (node.childNodes.length === 0) {
      return false
    }

    for (let i = (node.childNodes.length - 1); i >= 0; i--) {
      const checkNode = node.childNodes[i]
      if (checkNode.nodeType === 3 && !this.checkBogusNode(checkNode)) {
        return node
      } else if (checkNode.nodeType === 1) {
        if (this.findFistContentNodeInParentNode(checkNode)) {
          return this.findFistContentNodeInParentNode(checkNode)
        }
      }
    }

    return false
  },

  findPrevNode (node) {
    if (!node) {
      return false
    }
    if (
      node.nodeName === 'TD' ||
      node.nodeName === 'P' ||
      node.nodeName === 'DIV'
    ) {
      return false
    }

    const prevSib = node.previousSibling
    if (prevSib) {
      if (prevSib.nodeType === 3) {
        if (!this.checkBogusNode(prevSib)) {
          return prevSib
        }

        return this.findPrevNode(prevSib)
      } else if (prevSib.nodeType === 1) {
        if (prevSib.childNodes.length > 0) {
          const firstContentNode = this.findLastContentNodeInParentNode(prevSib)
          if (firstContentNode) {
            return firstContentNode
          }
        }

        return this.findPrevNode(prevSib)
      }
    } else if (node.parentNode) {
      return this.findPrevNode(node.parentNode)
    }
  },

  clearNode (node) {
    let newData = ''
    if (node.nodeType !== 3) {
      return node
    }
    for (let i = 0; i < node.data.length; i++) {
      if (node.data.charCodeAt(i) !== 65279) {
        newData += node.data.charAt(i)
      }
    }

    return newData
  },

  createBogusNode () {
    const emptyChar = String.fromCharCode(65279)
    const emptyText = document.createTextNode(emptyChar)

    return emptyText
  },

  findParentContainer (node) {
    if (!node || node.nodeType !== 1) {
      return false
    }
    let containsNode = false
    if (node.classList && node.classList.contains('editor-mount')) {
      containsNode = false
    }

    if (node.nodeName === 'P') {
      containsNode = node
    } else if (node.parentElement) {
      containsNode = this.findParentContainer(node.parentElement)
    }

    return containsNode
  },

  /**
   * check node
   * return boolean
   */
  isNode (node) {
    if (node && parseInt(node.nodeType) === 1) {
      return true
    }

    return false
  },

  /**
  * set nextItem on tab key
  */
  setNextItem (currentIntem) {
    const items = this.switchStorage
    if (!items || items.length <= 0) {
      return
    }
    const index = items.findIndex((item) => item === currentIntem)
    if (index === -1) {
      return
    }
    if (items[index + 1]) {
      this.applicationParams.nextItem = items[index + 1]
    } else {
      this.applicationParams.nextItem = items[0]
    }
  },

  setBogusBefore (node, checkNode = false) {
    if (!node) {
      return false
    }
    if (checkNode) {
      if (this.checkBogusNode(checkNode)) {
        return false
      }
    }
    const bogus = this.createBogusNode()
    $(bogus).insertBefore(node)
    console.debug('setBogusBefore')

    return bogus
  },

  setBogusAfter (node, checkNode) {
    if (!node) return false
    if (checkNode) {
      if (this.checkBogusNode(checkNode)) {
        return false
      }
    }
    const bogus = this.createBogusNode()
    $(bogus).insertAfter(node)
    console.debug('set bogusAfter')

    return bogus
  },
}

export default editorHelper
