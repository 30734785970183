import selH from '../helpers/select_helper'
import { checkDynamicElement as checkDynamic } from './funcitons'
import PrintAreaManager from '../entities/print_area_manager'
import { SEMD_AREA, SEMD_ENTITY } from '@/plugins/dynamic_forms/configuration/reducer_types'
import { specSymbolsSanitize } from '@/specific/entries/submit_sanitizer'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import editorHelper from '@/plugins/dynamic_forms/custom_plugins/editorHelper'
import { debounce } from 'lodash'
import { GLOBAL_MAX_USER_WAITING_MS } from '@/_global_scripts/GLOBAL_CONSTS'

const TEXT_NODE = 3
const MOUSE_RIGHT_BUTTON = 2
export const FRONTEND_MOUNT_POINT = 'frontend-mount-point'

const emitSemdSelectorPopoverClose = (context) => {
  Services.pubSub.emit(`onSemdSelectorPopoverClose:${context.ed.id}`, { context })
}

const emitSemdSelectorClick = (context) => {
  emitSemdSelectorPopoverClose(context)

  if (context.event.button !== MOUSE_RIGHT_BUTTON) { return }
  Services.pubSub.emitAsync(`onSemdSelectorClick:${context.ed.id}`, { context })
}

const redrawSemdAreas = (context) => {
  Services.pubSub.emit(`linkSemdSelectItems:${context.ed.id}`, 'all')
}

const debouncedRedrawSemdAreas = debounce(redrawSemdAreas, GLOBAL_MAX_USER_WAITING_MS)

const eventManagerExtension = {
  cloneRange (range) {
    if (!range) return
    const newRange = {}
    for (const prop in range) {
      newRange[prop] = range[prop]
    }

    return newRange
  },

  restoreRange (range) {
    if (!range) return
    selH.selectNodeRange(range.startContainer, range.startOffset + 1)
  },

  clearContainer () {
    if (this.startRangeBogus && this.startContainer.length > 1) {
      this.print_r('clear container')
      this.startContainer.data =
        this.startContainer.data.slice(this.startContainer.data.length - 1)
    }
  },

  setBogusCharsInStart (node) {
    console.debug('inner begin from not bogus')
    const saveRange = this.cloneRange(this.range)
    const emptyChar = String.fromCharCode(65279)
    this.node.firstChild.data = emptyChar + this.node.firstChild.data
    this.restoreRange(saveRange)
  },

  setEmptyChars () {
    this.mapEditables((element) => {
      if (element.innerHTML.charCodeAt(0) !== 65279) {
        const emptyChar = String.fromCharCode(65279)
        element.innerHTML = emptyChar + element.innerHTML
      }
    })
  },

  handleMouseDown () {
    const that = this
    if (this.target.classList.contains(SEMD_ENTITY)) {
      emitSemdSelectorClick(that)
    }

    if (!checkDynamic(this.event.target) && !this.arrowKey) {
      this.mapElements((item) => item.setAttribute('contenteditable', true))
      window.unblock = true
    }
  },

  handleMouseUp () {
    if (window.unblock) {
      this.mapElements((item) => item.setAttribute('contenteditable', false))
      window.unblock = false
    }
  },

  handleSetContent (context, event, editor) {
    PrintAreaManager.checkPosition(event.content)
  },

  attachHandleContextMenu () {
    if (!this.inner) {
      return
    }
    if (this.node.classList.contains('ext_list')) {
      this.node.setAttribute('contenteditable', true)

      if (
        this.applicationParams.lastRange &&
        this.applicationParams.lastTarget === this.node
      ) {
        selH.selectNodeRange(
          this.node.firstChild,
          this.applicationParams.lastRange.start,
          this.applicationParams.lastRange.end
        )
      } else {
        const range = selH.selectNodeRange(this.node.firstChild,
          this.node.firstChild.length)
        this.applicationParams.lastRange = {
          start: range.startOffset,
          end: range.endOffset,
        }
      }
      this.applicationParams.lastTarget = this.node
    }
  },

  /**
   * install range on dynamic element
   */
  setRangeOnClick () {
    /** @type {HTMLElement} */
    const target = this.target
    const isSemdSelector = target.classList.contains(SEMD_ENTITY)
    const isSemdArea = target.classList.contains(SEMD_AREA)
    if (isSemdSelector || isSemdArea) {
      return
    }

    if (!this.inner) {
      this.mapElements((item) => item.setAttribute('contenteditable', false))
      this.applicationParams.lastTarget = false
      this.applicationParams.lastRange = false
      this.applicationParams.nextItem = false

      return
    }

    this.setNextItem(this.node)

    if (this.entity.isManualInput) {
      if (!this.node.getAttribute('contenteditable')) {
        this.node.setAttribute('contenteditable', true)
      }
      this.applicationParams.lastRange = {
        start: this.range.startOffset,
        end: this.range.endOffset,
      }
    }

    if (this.applicationParams.lastTarget === this.node) {
      return
    }

    if (this.checkEditable(this.node)) {
      this.node.setAttribute('contenteditable', true)
      selH.selectNode(this.node)
      this.applicationParams.lastTarget = this.node
      this.applicationParams
        .lastRange = { start: 0, end: this.node.innerText.length }
    }
  },

  /**
   * function checked is dynamic elment
   */
  checkDynamicElement () {
    if (this.inner && this.isVarialbe) {
      if (!this.digitalKey &&
        this.event.key !== '.' &&
        this.event.key !== '-' &&
        !this.back &&
        !this.arrowKey
      ) {
        event.preventDefault()
      }
    }

    if (this.inner && (this.back || this.del || this.enter)) {
      if (this.mode === 'backend') {
        this.preventAction('stop delete dynamic element')
      } else {
        this.node.setAttribute('contenteditable', true)
      }
    }
  },

  checkContainsDynElementsFromSel () {
    const selectedElement = this.ed.selection.getNode()
    if (selectedElement.classList.contains('t-element')) return
    if (this.isNode(this.range.commonAncestorContainer) && this.contentContainsElement) {
      if (this.del) {
        window.application.form_builder.current.container.get('editor').deleteContent()
      }
      this.preventAction('stop delete from content contains elements')
    }
  },

  attachHandleOnDynamicEl () {
    if (!this.inner) return
    if (this.back || this.space) {
      if (this.leftBorderDanger) {
        this.preventAction('stop delete from achieved left border element')
      }

      if (this.node.firstChild.length <= 1) {
        this.preventAction('stop delete from  minimal length', this.node)
      }

      if (this.sel.getSel().toString() === this.node.innerText) {
        this.node.innerText = String.fromCharCode(160)
        this.preventAction(
          'stop delete from selected all content from inner element'
        )
        this.applicationParams.lastRange = { start: 0, end: 1 }
      }
    }
  },

  attachHandleOnCtrlKey () {
    if (
      this.ctrlKey &&
      this.event.keyCode !== 86 &&
      this.event.keyCode !== 90 &&
      this.event.keyCode !== 67
    ) {
      this.preventAction('prevent from press ctrl key')
    }
  },

  removeBrNodesIn (container) {
    $(container)
      .find('br')
      .remove()
  },

  moveCaretTo (bogusNode) {
    selH.selectNodeRange(bogusNode, 0)
  },

  mergeRowsWhenTopRowEndsWithDynamicManually () {
    const currentRow = this.rootContainer
    const topRow = currentRow.previousElementSibling
    const lastNodeBeforeMerging = topRow.lastChild

    this.removeBrNodesIn(currentRow)
    if (this.startRangeBogus) { this.startContainer.remove() }

    topRow.insertAdjacentHTML('beforeend', currentRow.innerHTML)
    currentRow.remove()
    this.removeBrNodesIn(topRow)

    const bogusBetweenMergedRows = this.checkBogusNode(lastNodeBeforeMerging)
      ? lastNodeBeforeMerging
      : this.setBogusAfter(lastNodeBeforeMerging)

    this.moveCaretTo(bogusBetweenMergedRows)
    this.event.preventDefault()
  },

  transferDynamicElement () {
    //trick for double bogus
    if (!(this.enter && this.startContainer.nodeType === TEXT_NODE)) { return }

    if (!(this.startRangeBogus || this.startRangePreventelement)) { return }

    let beforeInsertNode = this.startContainer
    let checkPointNode = false
    if (!this.startRangeBogus) {
      const bogusNode = this.setBogusBefore(this.nextNode)
      beforeInsertNode = bogusNode
      this.startContainer.data = this.clearNode(this.startContainer)
    }

    if (
      this.checkBogusNode(beforeInsertNode) &&
      beforeInsertNode.length > 1
    ) {
      checkPointNode = beforeInsertNode
    } else {
      checkPointNode = this.setBogusBefore(beforeInsertNode)
    }

    selH.selectNodeRange(checkPointNode, 0)
  },

  checkClick () {
    if (
      this.range.startContainer.nodeType === TEXT_NODE &&
      this.range.startOffset === 0 &&
      this.range.collapsed &&
      this.inner
    ) {
      const checkPointNode = this.setBogusBefore(this.node)
      selH.selectNodeRange(checkPointNode, 0)

      return false
    }
  },

  checkPressDeleteKey () {
    if (!this.del) {
      return
    }

    const that = this
    if (
      this.range?.startContainer?.innerHTML &&
      (this.range.startContainer.innerHTML.includes('semd-entity') ||
        this.range.startContainer.innerHTML.includes('semd-area'))
    ) {
      this.event.preventDefault()
      const deletableId = this.getDataIdFromString(this.range.startContainer.innerHTML)
      Services.pubSub.emit(`removeSemdEntity:${this.ed.id}`, deletableId)
      emitSemdSelectorPopoverClose(that)
    }

    if (
      this.nextSibling &&
      this.nextSibling?.classList &&
      this.nextSibling.classList.contains('semd-entity')
    ) {
      Services.pubSub.emit(`removeSemdEntity:${this.ed.id}`, this.nextSibling.id)
    }
    this.onDeleteEgiszContentArea(this.nextSibling)

    if (this.startRangePreventelement && this.collapsed) {
      this.preventAction('stop delete from next element dynamic')
    }

    if (this.content.includes('print_area_start') || this.content.includes('print_area_end') ||
      (this.nextSibling && this.nextSibling.classList &&
        (this.nextSibling.classList.contains('print_area_start') ||
          this.nextSibling.classList.contains('print_area_end')))) {
      this.preventAction('stop delete print area')
    }

    debouncedRedrawSemdAreas(this)
  },

  checkPressEnterKey () {
    if (!this.enter) { return }
    this.transferDynamicElement()
    if (this.content.includes('print_area_start') || this.content.includes('print_area_end')) {
      this.preventAction('stop delete print area')
    }

    debouncedRedrawSemdAreas(this)
  },

  checkBackspace () {
    if (!this.back) {
      return
    }

    if (
      this.range?.endContainer?.innerHTML &&
      (this.range.endContainer.innerHTML.includes('semd-entity') ||
        this.range.endContainer.innerHTML.includes('semd-area'))
    ) {
      this.event.preventDefault()
      const deletableId = this.getDataIdFromString(this.range.endContainer.innerHTML)
      Services.pubSub.emit(`removeSemdEntity:${this.ed.id}`, deletableId)
      emitSemdSelectorPopoverClose(this)
      debouncedRedrawSemdAreas(this)

      return
    }

    if (
      this.previousSibling &&
      this.previousSibling?.classList &&
      this.previousSibling.classList.contains('semd-entity')
    ) {
      Services.pubSub.emit(`removeSemdEntity:${this.ed.id}`, this.previousSibling.id)
    }

    this.onDeleteEgiszContentArea(this.previousSibling)

    if (this.nextNodeDanger && this.nextNodeDynamic) {
      this.nextNode.setAttribute('contenteditable', true)
      window.ublockElement = this.nextNode
    }

    if (this.prevNodeDynamic) {
      if (
        !this.prevNode.nextSibling ||
        !this.checkBogusNode(this.prevNode.nextSibling)
      ) {
        this.setBogusAfter(this.prevNode)
      }
    }

    if (this.prevNodeBlockDel) {
      this.preventAction('stop delete from previous element is dynamic')
    }

    if (this.isTopContainerEndsWithDynamicVisuallyAndCanMergeIt) {
      this.mergeRowsWhenTopRowEndsWithDynamicManually()
    }

    if (this.content.includes('print_area_start') || this.content.includes('print_area_end') ||
      (this.previousSibling && this.previousSibling.classList &&
        (this.previousSibling.classList.contains('print_area_start') ||
          this.previousSibling.classList.contains('print_area_end')))) {
      this.preventAction('stop delete print area')
    }

    if (this.startRootContainer && !this.collapsed) {
      this.print_r('inserted')
      this.setBogusBefore(this.nextNode)
    }

    this.setBogusBefore(this.nextNode, this.nextNode.previousSibling)

    if (this.startRangeBogus && this.startContainer.length > 1) {
      this.startContainer.data = this.startContainer
        .data.slice(this.startContainer.data.length - 1)
    }

    debouncedRedrawSemdAreas(this)
  },

  checkPressTabKey () {
    if (this.tabKey) {
      if (this.applicationParams.nextItem) {
        this.mapElements((item) => item.setAttribute('contenteditable', false))
        this.applicationParams.nextItem.setAttribute('contenteditable', true)
        selH.selectNode(this.applicationParams.nextItem.firstChild)
        this._deleteContextMenu(true)

        const isContexted = this.entityManager.getEntity(this.applicationParams.nextItem).contexted
        if (isContexted) {
          this.event.target = this.applicationParams.nextItem
          const offset = $(this.event.target).offset()
          this.event.pageY = offset.top + 2
          this.event.pageX = offset.left + 15
          window.application.form_builder
            .current.container.get('contextMenu').build(
              tinymce.activeEditor,
              this.event
            )
        }

        this.setNextItem(this.applicationParams.nextItem)
      }
      this.event.preventDefault()
    }
  },

  checkDrop () {
    const elementId = this.event?.targetClone?.getAttribute('data-id') || ''
    Services.pubSub.emit(`linkSemdSelectItems:${this.ed.id}`, elementId)
  },

  async onDeleteEgiszContentArea (sibling) {
    const _sibling = sibling || editorHelper.findNextNode(this.node)

    const content = this.sel.getContent()
    const siblingOuterHtml = _sibling?.outerHTML || ''
    const $content = content
      ? $(`<div>${specSymbolsSanitize(content)}</div>`)
      : $(`<div>${specSymbolsSanitize(siblingOuterHtml)}</div>`)

    if ($content.find('.semd-entity,.semd-area').length) {
      this.event.preventDefault()
      const { cancel } = await MConfirm.error(t('semds.delete.egiszLayout'))
      if (!cancel) { Services.pubSub.emit(`onDeleteContentArea:${this.ed.id}`, $content) }
    }
  },

  getDataIdFromString (text) {
    const regex = /data-id="([^"]*)"/g
    const matches = [...text.matchAll(regex)]
    const values = matches.map((match) => match[1])

    if (matches && matches.length) {
      return values.at(-1)
    }

    return null
  },

  _deleteContextMenu () {
    window.application.form_builder.current.container.get('contextMenu').delete()
  },

  checkPressArrow () {
    if (!this.arrowKey) {
      return false
    }

    if (this.leftKey) {
      if (this.prevNodeDynamic && this.prevNodeDanger) {
        this.setBogusAfter(this.prevNode, this.prevNode.nextSibling)
        this.prevNode.setAttribute('contenteditable', true)
      }

      if (this.leftBorderDanger) {
        if (this.node.previousSibling === null) {
          this.setBogusBefore(this.node)
        }
      }

      if (this.inner && !this.stCBegindBogus) {
        this.setBogusCharsInStart()
      }
    }

    if (this.rightKey) {
      if (this.nextNodeDynamic && this.nextNodeDanger) {
        this.setBogusBefore(this.nextNode, this.nextNode.previousSibling)
        this.nextNode.setAttribute('contenteditable', true)
      }
    }
  },
}

export default eventManagerExtension
